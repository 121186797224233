import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { RxDotsVertical } from "react-icons/rx";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Label from "./Label";
import Wrapper from "../potree/shared/Wrapper";

interface PopupMenuProps {
  id: number | string;
  label?: string;
  handleEdit?: (id: string) => void;
  handleDelete?: (id: string) => void;
  handleShare?: (id: string) => void;
  editLabel?: string;
  deleteLabel?: string;
  shareLabel?: string;
  menuIcon?: IconType;
  menuIconStyles?: string;
  popupMenuStyles?: string;
  classNames?: string;
}

const PopupMenu: React.FC<PopupMenuProps> = ({
  id,
  label,
  handleDelete,
  handleEdit,
  handleShare,
  editLabel = "edit",
  deleteLabel = "delete",
  shareLabel = "share",
  menuIcon: Icon,
  menuIconStyles,
  popupMenuStyles,
}) => {
  const { t } = useTranslation();

  return (
    <Menu as="div" className="relative inline-block">
      <Wrapper classNames="flex flex-row justify-between gap-4">
        {label && <Label text={label} />}
        <Menu.Button onClick={(e) => {e.stopPropagation()}}>
          {Icon ? (
            <Icon
              size={24}
              className={`
              ${
                menuIconStyles
                  ? menuIconStyles
                  : "text-secondary-foreground hover:text-secondary"
              }`}
            />
          ) : (
            <RxDotsVertical
              size={24}
              className={`
              ${
                menuIconStyles
                  ? menuIconStyles
                  : "text-accent hover:text-secondary"
              }`}
            />
          )}
        </Menu.Button>
      </Wrapper>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            popupMenuStyles ?? "right-0"
          } text-white absolute z-10 mt-2 w-32 origin-top-right rounded-md bg-[#333333] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <Wrapper>
            {handleEdit && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    className={`capitalize block px-4 py-2 ${
                      active ? "hover:text-secondary" : "visited:text-white"
                    }`}
                    onClick={() => handleEdit(id as string)}
                  >
                    {editLabel && t(`${editLabel}`)}
                  </Link>
                )}
              </Menu.Item>
            )}

            {handleDelete && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    className={`capitalize block px-4 py-2 ${
                      active ? "hover:text-secondary" : "visited:text-white"
                    }`}
                    onClick={() => handleDelete(id as string)}
                  >
                    {deleteLabel && t(`${deleteLabel}`)}
                  </Link>
                )}
              </Menu.Item>
            )}

            {handleShare && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    className={`capitalize block px-4 py-2 ${
                      active ? "hover:text-secondary" : "visited:text-white"
                    }`}
                    onClick={() => handleShare(id as string)}
                  >
                    {shareLabel && t(`${shareLabel}`)}
                  </Link>
                )}
              </Menu.Item>
            )}
          </Wrapper>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PopupMenu;
