export const MENU = {
  Photos: [
    {
      toolName: "panorama",
      action: "togglePanoramaTour",
      toolIconSrc: "camera.svg",
    },
  ],
  Navigate: [
    {
      toolName: "select",
      action: null,
      toolIconSrc: "select.svg",
    },
    {
      toolName: "box",
      action: null,
      toolIconSrc: "box.svg",
    },
  ],
  Measure: [
    {
      toolName: "height",
      action: "addHeightMeasurement",
      toolIconSrc: "vertical.svg",
    },
    {
      toolName: "horizontal",
      action: "addHorizontalMeasurement",
      toolIconSrc: "horizontal.svg",
    },
    {
      toolName: "distance",
      action: "addDistanceMeasurement",
      toolIconSrc: "incline.svg",
    },
    {
      toolName: "point",
      action: "addPointMeasurement",
      toolIconSrc: "dot.svg",
    },
    {
      toolName: "remove",
      action: "addRemoveMeasurements",
      toolIconSrc: "recycle-bin.svg",
    },
  ],
  Annotate: [
    {
      toolName: "annotation",
      action: "addAnnotation",
      toolIconSrc: "plus.svg",
    },
  ],
};
