import { useTranslation } from "react-i18next";
import { cn } from "../../utils/helper";

interface LabelProps {
  text: string;
  classNames?: string;
}

const Label: React.FC<LabelProps> = ({ text, classNames }) => {
  const { t } = useTranslation();

  return (
    <label
      className={cn("text-base-regular capitalize", classNames)}
    >
      {t(`${text}`)}
    </label>
  );
};

export default Label;
