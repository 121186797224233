import { GrMenu } from "react-icons/gr";
import { GrClose } from "react-icons/gr";
import { ChangeEvent, ReactNode, useMemo } from "react";
import { cn, getDefaultOptions } from "../../../utils/helper";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import Accordion from "../shared/Accordion";
import AddNote from "../note/AddNote";
import EditNote from "../note/EditNote";
import Shortcuts from "../shared/Shortcuts";
import Label from "../../shared/Label";
import MultiCheckbox from "../../shared/MultiCheckbox";

const LeftSidebar: React.FC = () => {
  const { project, potreeViewer, projectPcs, setProjectPcs } =
    usePotree();
  const { editNoteFormId } = useToggle();

  const accordionBody = useMemo(
    () => (
      <Wrapper classNames="flex flex-col gap-8">
        {/* <ProgressBar progress={40} label="point_budget" /> */}

        <MultiCheckbox
          fieldName="daa"
          checkedValues={projectPcs
            .filter((obj: any) => obj.visibile)
            .map((obj: any) => obj.id)}
          defaultOptions={
            projectPcs ? getDefaultOptions(projectPcs, "label", "id") : []
          }
          handleChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            value: string | number
          ) => {
            potreeViewer.scene.pointclouds.forEach((pc: any) => {
              if (pc.o_id === value) {
                pc.visible = !pc.visible;
                setProjectPcs((prev: any) =>
                  prev.map((item: any) => {
                    if (item.id === value) {
                      item.visibile = pc.visible;
                    }
                    return item;
                  })
                );
              }
            });
          }}
        />

        {/* 
          NOTE: commented out because something is broken. 
          Either the backend seems to not support this feature yet, or there is a bug in the frontend 
        */}
        {/* <Authenticated>
          <Notes />
        </Authenticated> */}
      </Wrapper>
    ),
    [projectPcs, potreeViewer, setProjectPcs]
  );

  if (!project) return null;

  return (
    <Wrapper elementType="aside">
      <LeftSide>
        {project ? (
          <Wrapper classNames="h-full flex flex-col justify-between gap-8 overflow-scroll">
            <Accordion
              label="pointclouds"
              body={accordionBody}
              defaultOpen={true}
            />

            <Shortcuts />
          </Wrapper>
        ) : (
          <Wrapper>No projects found</Wrapper>
        )}
      </LeftSide>

      <RightSide>
        {project.field_notes.map(
          (note) =>
            note.cid === editNoteFormId && (
              <EditNote key={`note__${note.cid}`} note={note} />
            )
        )}

        {editNoteFormId === "new" && <AddNote />}
      </RightSide>
    </Wrapper>
  );
};

const LeftSide = ({ children }: { children: ReactNode }) => {
  const { isLeftSideMenuOpen } = useToggle();

  return (
    <Wrapper
      elementType="section"
      classNames={
        cn(
          "z-50 bg-white text-primary fixed top-3 bottom-3 left-3 rounded-lg p-4 w-[280px] transition-transform transform",
          isLeftSideMenuOpen ? "translate-x-0" : "-translate-x-[292px]",
          isLeftSideMenuOpen ? "shadow-2xl drop-shadow-2xl" : ""
        )
      }
    >
      <MenuToggle />
      {children}
    </Wrapper>
  );
};

const RightSide = ({ children }: { children: ReactNode }) => {
  const { isOpenSubmenu } = useToggle();

  return (
    <Wrapper
      elementType="section"
      styles={{
        height: "calc(100% - 78px)",
        width: "calc(100% - 316px)",
        transform: `${isOpenSubmenu ? "translateX(0)" : "translateX(calc(100% + 12px))"
          }`,
      }}
      classNames='z-50 bg-white text-primary fixed bottom-3 right-3 rounded-lg p-4 transition-transform transform overflow-scroll'
    >
      {children}
    </Wrapper>
  );
};

const MenuToggle = () => {
  const { project } = usePotree();
  const {
    isLeftSideMenuOpen,
    setIsOpenSubmenu,
    setIsLeftSideMenuOpen,
  } = useToggle();

  return (
    <Wrapper classNames="absolute top-3 left-[302px]">
      <button
        onClick={() => {
          setIsLeftSideMenuOpen(() => !isLeftSideMenuOpen);
          if (isLeftSideMenuOpen) {
            setIsOpenSubmenu(false);
          }
        }}
        className="text-foreground flex items-center gap-4"
      >
        {isLeftSideMenuOpen ? (
          <GrClose size={24} />
        ) : (
          <GrMenu size={24} />
        )}

        <Label
          text={project?.label ?? ""}
          classNames="text-md-regular whitespace-nowrap"
        />
      </button>
    </Wrapper>
  );
};

export default LeftSidebar;
