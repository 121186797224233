import { Outlet } from "react-router-dom";
import Footer from "../components/admin/Footer";
import Container from "../components/shared/Container";
import AdminHeader from "../components/admin/AdminHeader";
import { useTheme } from "../components/shared/ThemeProvider";

const AdminLayout: React.FC = () => {

  // Per default force a dark theme on this layout
  const theme = useTheme();
  theme.setTheme("dark")

  return (
    <div className="text-sm-regular flex flex-col min-h-screen min-w-screen">
      <AdminHeader />
      <main className="flex-grow">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default AdminLayout;
