import { potreeTools } from "../../../utils/potreeTools";
import { usePotree } from "../../../hooks/usePotree";
import { useTheme } from "../../shared/ThemeProvider";
import { cn } from "../../../utils/helper";

interface ToolButtonProps {
  toolName: string;
  action: string | (() => void) | null;
  toolIconSrc: string;
  classNames?: string;
  inactive?: boolean;
}

const ToolButton: React.FC<ToolButtonProps> = ({
  toolName,
  action,
  toolIconSrc,
  classNames,
  inactive
}) => {
  const { potreeViewer } = usePotree();

  const { theme } = useTheme();

  const handleClick = (toolName: string, action: string | (() => void) | null) => {
    console.log("ToolButton.handleClick()", toolName, action, `inactive: ${inactive}`);
    if (action && !inactive) {
      if (typeof action === "string" || action instanceof String){
        potreeTools[action as string](potreeViewer);
      } else {
        action();
      }
    }
  };

  return (
    <div
      className={cn(
        "transition hover:scale-150 py-3 px-2 max-w-[32px]",
        inactive
          ? "cursor-not-allowed opacity-50 hover:!scale-100"
          : "cursor-pointer",
        classNames
      )}
      onClick={() => handleClick(toolName, action)}
    >
      <img
        src={`/assets/icons/${theme}__${toolIconSrc}`}
        alt={`${toolName} icon`}
        title={toolName}
      />
    </div>
  );
};

export default ToolButton;
