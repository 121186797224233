import { ChevronDown } from "lucide-react";
import { usePotree } from "../../../hooks/usePotree";
import { Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel } from "../../shadcn-ui/sidebar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../shadcn-ui/collapsible";
import SceneSlides from "../scenes/SceneSlides";

const RightSidebar = () => {
  const { potreeViewer, project } = usePotree();

  if (!potreeViewer) return null;

  const sceneSlides = project?.field_scenes ?? null;

  return (
    <Sidebar side="right" className="border-none max-w-[270px]">
      {/* <SidebarHeader></SidebarHeader> */}
      <SidebarContent>

        <Collapsible defaultOpen className="group/collapsible">
          <SidebarGroup>
            <SidebarGroupLabel asChild>
              <CollapsibleTrigger>
                Slides
                {/* TODO use different icon? I.e. not from lucide-react */}
                <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
              </CollapsibleTrigger>
            </SidebarGroupLabel>
            <CollapsibleContent>
              <SidebarGroupContent className="p-2 mt-2">
                {sceneSlides &&
                  Object.entries(sceneSlides).map(
                    ([sceneSlideKey, { label, scenes }]) => (
                      <SceneSlides
                        key={`scene-slide__${sceneSlideKey}`}
                        sceneSlideKey={sceneSlideKey}
                        sceneSlideScenes={scenes}
                      />
                    )
                  )}
              </SidebarGroupContent>
            </CollapsibleContent>
          </SidebarGroup>
        </Collapsible>
      </SidebarContent>
      {/* <SidebarFooter ></SidebarFooter> */}
    </Sidebar>
  );
};

export default RightSidebar;
