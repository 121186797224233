import { cn } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import { GrDown } from "react-icons/gr";

const locales: {
  [key: string]: {
    title: string;
  };
} = {
  en: { title: "EN" },
  de: { title: "DE" },
};

const LanguageSwitcher = ({
  classNames,
}: {
  classNames?: string;
}) => {
  const { i18n } = useTranslation();

  return (
    <div className="group flex flex-row items-center">
      <select
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        className={cn("appearance-none focus:none outline-none hover:cursor-pointer py-2 px-4 pr-10 rounded-lg bg-secondary-foreground group-hover:text-secondary", classNames)}
      >
        {Object.keys(locales).map((locale) => (
          <option value={locale} key={`language_switcher_item_${locale}`}>
            {locales[locale].title}
          </option>
        ))}
      </select>
      <div className="pointer-events-none relative h-0 w-0 flex items-center justify-center">
        <GrDown
          size={16}
          className={cn("absolute -left-8 bg-secondary-foreground group-hover:text-secondary", classNames)}
        />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
